import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isSignature: false,
    isSaved: false,
    ids: {
      // pacientId: '1',
      // tratamentId: 1
    }
  },
  mutations: {
    SET_IDS (state, value) {
      state.ids = value
    },
    SIGNATURE_SAVED (state) {
      state.isSaved = true
      state.ids = {}
      setTimeout(() => {
        state.isSaved = false
      }, 5000)
    }
  },
  actions: {
    async get_pacient_details ({commit}) {
      try {
        const url = '/api/signature'
        console.log(`get signature details`)
        const {data} = await axios(url)
        data?.pacientId && commit('SET_IDS', data)
      } catch (err) {

      }
    },
    async save_signature ({commit}, signatureData) {
      try {
        const url = '/api/signature/save'
        const { data } = await axios.post(url, {signature: signatureData})
        commit('SIGNATURE_SAVED')
      } catch (e) {

      }
    }
  },
  modules: {
  }
})
